import React, { useState } from "react";
import {
  Typography,
  Button,
  Container,
  Box,
  TextField,
  CssBaseline,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Logo from "./NimbleTill.png";
import "./App.css";
import { db } from "./firebase-config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import ReCAPTCHA from "react-google-recaptcha";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#222",
    },
  },
  typography: {
    fontFamily: "Poppins, Arial, sans-serif",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 25,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: "linear-gradient(180deg, #6AD0D6 50%, #cfd9df 90%)",
          backgroundAttachment: "fixed",
        },
      },
    },
  },
});

function App() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const [captchaValue, setCaptchaValue] = useState(null);

  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    if (!email) {
      return "Email can't be empty.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      return "Please enter a valid email address.";
    }
    return "";
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(validateEmail(emailValue));
  };

  const handleBlur = () => {
    setEmailError(validateEmail(email));
  };

  const saveEmailToFirestore = async (email) => {
    setLoading(true);
    try {
      await addDoc(collection(db, "emails"), {
        email: email,
        timestamp: serverTimestamp(),
      });
      //console.log("Email successfully saved!");
      setEmail("");
      setDialogMessage("Your email has been successfully saved!");
      setDialogOpen(true);
    } catch (error) {
      //console.error("Error saving email: ", error);
      setDialogMessage(
        "An error occurred while saving your email. Please try again."
      );
      setDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!captchaValue) {
      setDialogMessage("Please verify you are not a robot.");
      setDialogOpen(true);
      return;
    }
    saveEmailToFirestore(email);
  };

  const onCaptchaChange = (value) => {
    //console.log("Captcha value:", value);
    setCaptchaValue(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mb: 4, minWidth: "365px" }}>
        <Paper
          elevation={6}
          sx={{
            mt: 5,
            py: 4,
            backgroundColor: "secondary.main",
            color: "#fff",
            borderRadius: "15px",
            textAlign: "center",
            padding: 2,
          }}
        >
          <img
            src={Logo}
            className="logo"
            alt="Nimble Till Logo"
            style={{
              maxWidth: "400px",
              width: "90%",
              height: "auto",
              margin: "0 auto",
              transition: "width 0.3s",
              marginLeft: 2,
            }}
          />
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: theme.palette.primary.main, fontWeight: 200 }}
          >
            Embark on a journey to redefine the retail experience with
          </Typography>
          <Typography
            variant="h5"
            sx={{
              letterSpacing: "0.1em",
              color: "#6AD0D6",
              fontWeight: 600,
            }}
          >
            NimbleTill
          </Typography>
          <Typography
            variant="h5"
            s
            sx={{ mb: 4, color: theme.palette.primary.main, fontWeight: 200 }}
          >
            The future of POS systems for small businesses.
          </Typography>
          <Container>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, textAlign: "center" }}
            >
              Stay Informed
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ textAlign: "center", marginBottom: 4 }}
            >
              Sign up to get the latest updates about NimbleTill.
            </Typography>
            <Grid
              display={"flex"}
              flexDirection={"column"}
              container
              gap={2}
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <TextField
                fullWidth
                error={!!emailError}
                placeholder="Email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleBlur}
                variant="outlined"
                type="email"
                InputLabelProps={{ style: { color: "#fff" } }}
                InputProps={{ style: { color: "#fff" } }}
                sx={{
                  maxWidth: "400px",
                  input: { color: "#fff" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": { borderColor: "#fff" },
                    "&:hover fieldset": {
                      borderColor: theme.palette.primary.light,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <ReCAPTCHA
                  sitekey="6Lex26wpAAAAADorqHzapasLBZ4qg8vE8Y8NuK3E"
                  onChange={onCaptchaChange}
                />
                <Button
                  variant="contained"
                  disabled={
                    loading || !!emailError || email === "" || !captchaValue
                  }
                  onClick={handleSubmit}
                  sx={{
                    mt: 2,
                    paddingHorizontal: 2,
                    width: "90%",
                    backgroundColor: "#6AD0D6",
                    "&.Mui-disabled": {
                      backgroundColor: "rgba(106, 208, 214, 0.5)",
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                  }}
                >
                  {loading ? "Submitting..." : "Subscribe"}
                </Button>
                {loading && <CircularProgress />}
              </Box>
            </Grid>
          </Container>
        </Paper>
        <Box
          component="footer"
          sx={{
            py: 3,
            mt: 4,
            color: "white",
            textAlign: "center",
            borderRadius: 4,
          }}
        >
          <Typography variant="h6">
            &copy; {new Date().getFullYear()} Nimble Till. All rights reserved.
          </Typography>
        </Box>
      </Container>
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ textAlign: "center" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"Subscribe to Nimble Till Updates"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogOpen(false)}
            color="secondary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;
