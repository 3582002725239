// src/firebase-config.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAZ2NrXVokKUhyhL_DCiqzxR5ImC7ooolk",
  authDomain: "nimbletill.firebaseapp.com",
  projectId: "nimbletill",
  storageBucket: "nimbletill.appspot.com",
  messagingSenderId: "450684736139",
  appId: "1:450684736139:web:8057aa06964b501a55ba8e",
  measurementId: "G-5DC2GVK3QE",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
const analytics = getAnalytics(app);
